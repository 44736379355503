<ng-container [ngSwitch]="data?.buttonType || type">
  <ng-container *ngSwitchCase="'icon-button'">
    <button
      [id]="actionRef.id"
      mat-icon-button
      [color]="color"
      [attr.aria-label]="actionRef.description || actionRef.title | translate"
      [attr.title]="actionRef.description || actionRef.title | translate"
      [disabled]="actionRef.disabled"
      (click)="runAction()"
    >
      <adf-icon [value]="actionRef.icon"></adf-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'flat-button'">
    <button
      [id]="actionRef.id"
      [color]="data?.color || color"
      mat-flat-button
      [attr.aria-label]="actionRef.description || actionRef.title | translate"
      [attr.title]="actionRef.description || actionRef.title | translate"
      [disabled]="actionRef.disabled"
      (click)="runAction()"
    >
      <span *ngIf="actionRef.title" data-automation-id="flat-button-title">{{ actionRef.title | translate }}</span>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'menu-item'">
    <app-toolbar-menu-item [actionRef]="actionRef"></app-toolbar-menu-item>
  </ng-container>
</ng-container>
