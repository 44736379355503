<form class="aca-rule-details__form" [ngClass]="{ 'read-only': readOnly }" [formGroup]="form">

  <ng-container *ngIf="!preview">
    <div class="aca-rule-details__form__row aca-rule-details__form__name">
      <label for="rule-details-name-input">{{ 'ACA_FOLDER_RULES.RULE_DETAILS.LABEL.NAME' | translate }}</label>
      <div>
        <mat-form-field floatLabel='never'>
          <input
            id="rule-details-name-input"
            matInput type="text" formControlName="name" data-automation-id="rule-details-name-input"
            [placeholder]="getPlaceholder('name') | translate">
          <mat-error>{{ getErrorMessage(name) | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="aca-rule-details__form__row aca-rule-details__form__description">
      <label for="rule-details-description-textarea">{{ 'ACA_FOLDER_RULES.RULE_DETAILS.LABEL.DESCRIPTION' | translate }}</label>
      <div>
        <mat-form-field floatLabel='never'>
        <textarea
          id="rule-details-description-textarea"
          matInput formControlName="description" data-automation-id="rule-details-description-textarea"
          [placeholder]="getPlaceholder('description') | translate">
        </textarea>
        </mat-form-field>
      </div>
    </div>
  </ng-container>

  <div class="aca-rule-details__form__row aca-rule-details__form__triggers">
    <div class="label">{{ 'ACA_FOLDER_RULES.RULE_DETAILS.LABEL.WHEN' | translate }}</div>
    <div>
      <aca-rule-triggers formControlName="triggers" data-automation-id="rule-details-triggers-component"></aca-rule-triggers>
      <mat-error class="rule-details-error">{{ getErrorMessage(triggers) | translate }}</mat-error>
    </div>
  </div>

  <div class="aca-rule-details__form__conditions">
    <aca-rule-composite-condition formControlName="conditions"></aca-rule-composite-condition>
    <mat-error class="rule-details-error">{{ getErrorMessage(conditions) | translate }}</mat-error>
  </div>

  <div class="aca-rule-details__form__row aca-rule-details__form__actions">
    <div class="label">{{ 'ACA_FOLDER_RULES.RULE_DETAILS.LABEL.PERFORM_ACTIONS' | translate }}</div>
    <aca-rule-action-list
      formControlName="actions"
      [actionDefinitions]="actionDefinitions"
      [parameterConstraints]="parameterConstraints"
      [readOnly]="readOnly"
      [nodeId]="nodeId">
    </aca-rule-action-list>
  </div>

  <div class="aca-rule-details__form__row" *ngIf="showOptionsSection">
    <div class="label">{{ 'ACA_FOLDER_RULES.RULE_DETAILS.LABEL.OPTIONS' | translate }}</div>
    <aca-rule-options
      formControlName="options"
      data-automation-id="rule-details-options-component"
      [errorScriptConstraint]="errorScriptConstraint">
    </aca-rule-options>
  </div>

</form>
