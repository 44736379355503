<ng-container *ngIf="expanded">
  <button
    mat-stroked-button
    data-automation-id="create-button"
    [matMenuTriggerFor]="rootMenu"
    title="{{ 'APP.NEW_MENU.TOOLTIP' | translate }}"
    [class]="{
      'app-create-menu-secondary-button': isMainActionPresent
    }"
  >
    <span class="app-create-menu__text">{{ 'APP.NEW_MENU.LABEL' | translate }}</span>
    <mat-icon title="{{ 'APP.NEW_MENU.TOOLTIP' | translate }}">arrow_drop_down</mat-icon>
  </button>
</ng-container>

<ng-container *ngIf="!expanded">
  <button
    mat-icon-button
    [class]="{
      'app-create-menu-secondary-button': isMainActionPresent,
      'app-create-menu--collapsed': true
    }"
    data-automation-id="create-button"
    [matMenuTriggerFor]="rootMenu"
    #createMenu="matMenuTrigger"
    title="{{ 'APP.NEW_MENU.TOOLTIP' | translate }}"
  >
    <mat-icon class="app-create-menu--icon" title="{{ 'APP.NEW_MENU.TOOLTIP' | translate }}">queue</mat-icon>
  </button>
</ng-container>

<mat-menu #rootMenu="matMenu" role="menu" class="app-create-menu__root-menu app-create-menu__sub-menu" [overlapTrigger]="false" yPosition="below">
  <div *ngFor="let action of createActions; trackBy: trackByActionId">
    <app-toolbar-menu-item [actionRef]="action"></app-toolbar-menu-item>
  </div>
</mat-menu>
