<div class="app-search-container">
  <button
    mat-icon-button
    id="app-search-button"
    class="app-search-button"
    (click)="searchSubmit(searchTerm)"
    [title]="'SEARCH.BUTTON.TOOLTIP' | translate"
  >
    <mat-icon [attr.aria-label]="'SEARCH.BUTTON.ARIA-LABEL' | translate">search</mat-icon>
  </button>
  <mat-form-field class="app-input-form-field" [floatLabel]="'never'">
    <input
      matInput
      #searchInput
      [attr.aria-label]="'SEARCH.INPUT.ARIA-LABEL' | translate"
      [type]="inputType"
      id="app-control-input"
      [(ngModel)]="searchTerm"
      (ngModelChange)="inputChange($event)"
      (keyup.enter)="searchSubmit($event)"
      [placeholder]="'SEARCH.INPUT.PLACEHOLDER' | translate"
    />
    <div matSuffix class="app-suffix-search-icon-wrapper">
      <mat-icon *ngIf="searchTerm.length" (click)="clear()" class="app-clear-icon">clear</mat-icon>
    </div>
  </mat-form-field>
</div>
