<div class="sidenav-header">
  <div class="sidenav-header-title">
    <div class="sidenav-header-title-logo"
      (click)="toggleNavBar.emit()"
      (keypress)="toggleNavBar.emit()">
      <img
        src="{{ logo$ | async }}"
        title="{{'APP.TOOLTIPS.COLLAPSE_NAVIGATION' | translate}}"
        alt="{{ 'CORE.HEADER.LOGO_ARIA' | translate }}" />
    </div>

    <div class="sidenav-header-title-text" [routerLink]="landingPage">
      {{ appName$ | async | translate }}
    </div>

    <ng-container *ngFor="let actionRef of actions; trackBy: trackByActionId">
      <aca-toolbar-action [actionRef]="actionRef"></aca-toolbar-action>
    </ng-container>
  </div>
</div>
