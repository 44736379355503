<div class="search-file-name">
  <span tabindex="0" role="link" *ngIf="isFile" (click)="showPreview($event)" (keyup.enter)="showPreview($event)" class="link">
    {{ name$ | async }}
  </span>
  <span tabindex="0" role="link" *ngIf="!isFile" (click)="navigate($event)" (keyup.enter)="navigate($event)" class="bold link">
    {{ name$ | async }}
  </span>
  <span>{{ title$ | async }}</span>
</div>
<div class="result-location">
  <aca-location-link [context]="context" [showLocation]="true"></aca-location-link>
</div>
